@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;1,900&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

p {
  margin: 0;
}
input {
  background-color: #e8ebef;
  border-radius: 0.25rem;
  border: none;
  padding: 6px 12px;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
label {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}
textarea {
  background-color: #e8ebef;
  border-radius: 0.25rem;
  border: none;
  padding: 6px 12px;
  font-size: 1rem;
  margin-bottom: 1.5rem;
  height: 100px;
}
h2{
  margin: 0;
  font-size: 3rem;

}
/*Sidebar*/
.sidebar {
  width: 32%;
  left: 0;
  top: 0;
  right: 552px;
  bottom: 0;
  z-index: 1;
  height: 100%;
  position: fixed;
  background-color: white;
  padding: 3rem;
}

.sidebar__name_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.sidebar__image {
  z-index: -1;
  max-height: 160px;
  max-width: 160px;
  position: relative;
}
.sidebar__name {
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 800;
  width: 210px;
  font-size: 50px;
  background-color: #f8f9fa;
  padding: 4px 9px;
  margin-bottom: 10px;
  margin-top: 0;
  list-style: none;
}
.text--subtitle {
  color: #5a6066;
  font-family: "Source Code Pro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin: 20px 0;
}
.location {
  background-color: yellow;
  font-style: italic;
  max-height: 20px;
}
.info{
  display: flex;
  justify-content: center;
  font-size: 1.1rem;
}
.pin {
  margin: 0 5px;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  align-items: center;
  justify-content: center;
}
.sidebar__links {
  text-transform: lowercase;
  text-decoration: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  margin: 0 5px;
}

.sidebar__links__list {
  padding: 0;
  margin: 20px;
}

.sidebar__links--projects {
  margin: 20px 0 0 0;
}

.sidebar__links--emoji {
  margin-left: 5px;
}

.down-arrow {
  display: none;
  padding-top: 80px;
  height: 30px;
  width: 30px;
}
/*Main*/
.main-container {
  height: 100vh;
}
.skills__experience__container {
  margin-left: 40%;
  width: 60%;
  position: relative;
  float: none;
  clear: none;
  overflow: visible;
}

.logos__container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: -50px;
}
.logos {
  justify-content: space-around;
  flex-flow: row wrap;
  width: 50%;
  display: flex;
  flex-flow: row wrap;
}

.skills__logo {
  width: 60px;
  height: 60px;
  padding: 20px;
}
.box {
  padding: 5px;
  overflow: auto;
  height: 50vh;
}
.box--title {
   font-weight: 800;
   text-transform: uppercase;
   font-size: 3rem;
 }
.box--title--contact {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 3rem;
}
.box__content__container {
  height: 100%;
  width: 100%;
}
.box--content {
  height: 100%;
  width: 100%;
  position: relative;
}

.box__header {
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
}

.contact__header {
  display: flex;
  flex-direction: row;
}

.box__emoji {
  margin-left: 10px;
}

/*Modifiers*/
.blue {
  background-color: #02fefe;
}
.green {
  background-color: #aaffa7;
}

.blog--title {
 text-decoration: underline;
  color: black;
  font-size: 1.2rem;
  font-family: Montserrat,sans-serif;
  font-weight: 900;
}
.blue--pattern {
  background: linear-gradient(90deg, #02fefe 21px, transparent 1%) center,
    linear-gradient(#02fefe 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.green--pattern {
  background: linear-gradient(90deg, #aaffa7 21px, transparent 1%) center,
    linear-gradient(#aaffa7 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.coral--pattern {
  background: linear-gradient(90deg, lightcoral 21px, transparent 1%) center,
    linear-gradient(lightcoral 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.black--pattern {
  background: linear-gradient(90deg, #235789 21px, transparent 1%) center,
  linear-gradient(#235789 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.yellow--pattern {
  background: linear-gradient(270deg, #ffd000 21px, transparent 1%) center,
    linear-gradient(#ffd000 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.pink--pattern {
  background: linear-gradient(270deg, #f8f9fa 21px, transparent 1%) center,
    linear-gradient(#f8f9fa 21px, transparent 1%) center, white;
  background-size: 23px 23px;
}

.banner {
  padding: 5px;
  overflow: hidden;
  height: 50vh;
}

/*Timeline*/
.timeline__container {
  width: 100%;
  text-align: center;
  position: relative;
  top: -20px;
}
.timeline {
  width: 100%;
  position: relative;
  transition: all 0.4s ease;
  color: black;
  height: 100%;
}
.timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #000;
  left: 50%;
  /* -webkit-transform: translate(-50%); */
  transform: translate(-50%);
  /* top: 0; */
  position: absolute;
}

.timeline__item {
  position: relative;
  text-align: right;
}
.--item-two {
  margin-top: 50px;
}

.timeline__icon {
  background: black;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -10px;
  border-radius: 50%;
}
.--clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.--date {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  max-width: 80px;
  text-align: center;
}
.--right {
  width: 45%;
  float: right;
  text-align: left;
}
.--experience {
  margin-bottom: 30px;
}
.--left {
  width: 45%;
  text-align: right;
}
.role {
  margin-top: 20px;
  font-size: 1rem;

}
.box__projects {
  min-width: 100% !important;
  z-index: 5;
  height: 500px;
  background-color: #ffd000;
  position: relative;
}

.project {
  z-index: 20;
  height: 270px;
  width: 300px;
  margin: 10px 10px 30px 10px;
  padding: 10px;
  background-color: white;
  border-radius: 0.25rem;
}
.blog__post {
  z-index: 20;
  width: 290px;
  min-height: 260px;
  max-height: 260px;
  height: 260px;
  margin: 10px 10px 28px 10px;
  padding: 10px;
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.blog__image__container{
  height: 70%;
}
.blog__image {
  border-radius: 0.25rem;
  max-height: 300px;
  min-height: 163px;
}
.project__content ul{
  list-style: none;
  display: flex;
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

}

.project__content {
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
}
.project__info {
  text-align: center;
  font-size: 13px;
  align-self: center;
  margin: 0;
  width: 100%;
  justify-self: center;
}
.project__video {
  border-radius: 0.25rem;
}
.project__buttons {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 5px;
}
.project__button {
  margin-right: 8px;
  border: none;
  background-color: black;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  text-align: center;
  max-width: 100px;
  min-width: 100px;
  padding: 8px;
}
.contact {
  height: 100vh;
  margin-left: 44%;
  position: relative;

}

.email__label {
  margin-top: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 600px;
  margin: 0 4rem;
}
.form__container {
  display: flex;
  justify-content: center;
  height: 100vh;
}
.form__submit__button {
  max-width: 100px;
  min-width: 100px;
  color: white;
  background-color: black;
  padding: 10px;
  text-align: center;
  border-radius: 0.25rem;
  border: none;
}

.form__error {
  margin-top: 20px;
  background-color: white;
  text-align: center;
  padding: 0.2rem;
  border: 1px solid #b0cdef;
  border-radius: 0.25rem;
  max-width: 100px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .contact{
    width: auto;
    width: initial;
    max-width: none;
  }
  .project__content ul{
    flex-direction: column;
  }

}
@media only screen and (max-width: 1280px) {
  .main-container {
    max-width: 100vw;
  }
  .skills__experience__container {
    width: 100%;
    margin-left: 0;
  }
  .sidebar__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sidebar {
    position: static;
    width: 100%;
    min-height: 100vh;
    max-width: 100vw;
    padding: 0px;
  }
  .sidebar__content {
    margin: 20px;
    margin-top: 30px;
    display: flex;
    height: 100vh;
  }
  .sidebar__image {
    z-index: -1;
    position: relative;
  }
  .sidebar__image__container {
    width: 100%;
    text-align: center;
    z-index: 10;
    position: relative;
  }
  .sidebar__name_container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 400;
    position: relative;
  }
  .down-arrow {
    display: inline;
    display: initial;
  }
  .--item-two {
    margin-top: 40px;
  }
  .timeline__container {
    margin-top: 20px;
  }
  .box__projects {
    height: auto;
    height: initial;
  }
  .contact {
    margin: 0;
    width: auto;
    width: initial;
  }
  .contact__header {
    justify-content: center;
  }
  label {

  }
  input {

  }
  textarea {

  }
  .form__submit__button {
max-width: none;
max-width: initial;
  }
}

@media only screen and (min-width: 1280px) {
  .sidebar__content {
    height: 80vh;
  }
  .skills__experience__container {
    margin-left: 44%;
    width: auto;
    width: initial;
  }
  .box--title {
    margin-left: 10px;
  }
  .box__projects {
    height: auto;
    height: initial;
  }
  .--item-two {
    margin-top: 50px;
  }


}
@media only screen and (min-width: 1280px) {
  .sidebar__content{
    height: 90vh;
    margin: 0;
  }
  .skills__experience__container {
    width: auto;
    width: initial;
    margin-left: 44%;
  }
  .box--title{
    margin-left: 10px;
  }
  .box__projects {
    height: auto;
    height: initial;
  }

  .form__submit__button {
    font-size: 1.3rem;
    padding: 10px;
    max-width: none;
    max-width: initial;
  }

}
@media only screen and (min-width: 3000px){
.blog--title {
  font-size: 1.1rem;
}
}
@media only screen and (max-width: 375px) {
  .project {
    margin: 20px 0;
  }
  .skills__logo {
    padding: 10px;
  }
  .box
  {
    font-size: 2.5rem;
  }
  .--item-two {
    margin-top: 10px;
  }
  .timeline__container {
    top: 65%;
  }
  .role {
    margin-top: 5px;
  }
  .--right{
    line-height: 2rem;
  }
  .--left{
    line-height: 2rem;
  }
  .--item-two{ margin-bottom: 20px
  }
}

@media only screen and (max-width: 341px) {
  .project {
    margin: 10px 0;
    padding: 10px;
  }
  .skills__logo {
    padding: 10px;
  }
  .box--title {
    font-size: 2.5rem;
  }
  .--item-two {
    margin-top: 10px;
  }
}


@media (max-width: 800px){
  .box {
    padding: 5px;
    overflow: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .logos {
    margin: 50px;
  }

  .box--title {
    font-size: 2.7rem;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 360px) {
  .box__emoji {
    display: none;
  }
  .--item-two {
    margin-top: 0;
  }
  .--date {
    padding: 5px;
    font-size: 1rem;
  }
  .project {
    margin: 10px 0;
  }
  .form__submit__button {
    font-size: 1.3rem;
    padding: 10px;
    margin-left: 40px;
    margin-right: 40px;
    max-width: none;
    max-width: initial;
  }
  @media only screen and (max-width: 360px) {
    .skills__logo {
      width: 50px;
      height: 50px;
    }
    .logos__container {
      margin-top: 20px;
    }
  }
}

.ml-3 {
  margin-left: 10px;
}
.center {
  text-align: center;
}

.overflow-hidden {
  overflow: hidden;
}
.--date {
  padding: 5px;
  font-size: 1rem;
}
